<!--
 * @Author: Su
 * @Date: 2020-11-24 14:56:55
 * @LastEditTime: 2020-11-28 17:38:22
 * @LastEditors: LazyQ
-->
<style lang="less" scoped>
.register-logo {
  width: 167px;
  height: 62px;
  position: absolute;
  left: 107px;
  top: 55px;
}
.result-vw {
  text-align: center;
  margin-top: 200px;
  .result-title {
    color: #0D1014;
    font-size: 24px;
  }
  .success-icon {
    width: 120px;
    height: 120px;
    margin: auto;
    left: 0;
    right: 0;
    margin-top: 60px;
  }
  .result-content {
    font-size: 18px;
    margin-top: 20px;
  }
}

</style>
<template>
  <div>
    <img src="@assets/images/new_logo.png" alt="" class="register-logo" />
    <div class="result-vw">
      <p class="result-title">调查结果</p>
      <img src="@assets/images/icon_success.png" class="success-icon">
      <p class="result-content">{{successTitle}}</p>
    </div>
  </div>
</template>

<script>
import { resultcallback } from "@/api/base.js"

export default {
  name: "Survey",
  data() {
    return {
      successTitle: "调研已完成，感谢您的参与",
      qquid: undefined,
      result: undefined,
      pid: undefined,
      loi: undefined,
      sd: undefined,
      pt: undefined,
      st: undefined,
      hash: undefined,
    }
  },
  created() {
    this.qquid = this.$route.query.mid;
    this.result = this.$route.query.menu;
    this.pid = this.$route.query.pid;
    this.loi = this.$route.query.loi;
    this.sd = this.$route.query.sd;
    this.pt = this.$route.query.pt;
    this.st = this.$route.query.st;
    this.hash = this.$route.query.hash;
    if (this.result == undefined || this.qquid == undefined) {
      this.successTitle = "调研已完成，感谢您的参与";
      return
    }
    if (this.result == "C") { /// 成功
      this.result = 3;
    }else if (this.result == "S") { /// 甄别
      this.successTitle = "您不满足这次调查的要求 ，请您参加其他调查";
      this.result = 1;
    }else if (this.result == "Q") { /// 配额满
      this.successTitle = "您不满足这次调查的要求 ，请您参加其他调查";
      this.result = 2;
    }
    this.initData()
  },
  methods: {
    initData() {
      this.fetchResult()
    },
    async fetchResult() {
      try {
        await resultcallback({
          qquid: this.qquid,
          result: this.result,
          pid: this.pid,
          loi: this.loi,
          sd: this.sd,
          pt: this.pt,
          st: this.st,
          hash: this.hash,
        });
        if (this.result == 3) {
          this.successTitle = "奖励已发放，感谢您的参与";
        }
      } catch (error) {
        console.log(error)
      }
    },
  },
};
</script>
